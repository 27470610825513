// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-2019-iceland-tsx": () => import("/opt/build/repo/src/pages/2019-iceland.tsx" /* webpackChunkName: "component---src-pages-2019-iceland-tsx" */),
  "component---src-pages-2019-tsx": () => import("/opt/build/repo/src/pages/2019.tsx" /* webpackChunkName: "component---src-pages-2019-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

