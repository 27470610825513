import React from 'react';

import { useResize } from 'hooks/use-resize';

interface IProps {
  children: React.ReactNode;
}

export const UIContext = React.createContext({});

export const UIProvider = ({ children }: IProps) => {
  const [canTransition, setCanTransition] = React.useState(true);
  const [overlayActive, setOverlayActive] = React.useState(false);
  const [activePosition, setActivePosition] = React.useState(-1);
  const [activeAlbum, setActiveAlbum] = React.useState('');
  const [activeArtwork, setActiveArtwork] = React.useState('');
  const [activeArtist, setActiveArtist] = React.useState('');
  const [activeQuote, setActiveQuote] = React.useState('');
  const [activeLink, setActiveLink] = React.useState('');

  const resize = useResize();
  const { isMobile, isDesktop } = resize;

  return (
    <UIContext.Provider
      value={{
        isMobile,
        isDesktop,
        activePosition,
        setActivePosition,
        activeArtist,
        setActiveArtist,
        activeArtwork,
        setActiveArtwork,
        setActiveQuote,
        activeQuote,
        setActiveLink,
        activeLink,
        activeAlbum,
        setActiveAlbum,
        canTransition,
        setCanTransition,
        overlayActive,
        setOverlayActive,
      }}
    >
      {children}
    </UIContext.Provider>
  );

};

export const withUIContext = (Component: any) =>
  (props: any) => (
    <UIContext.Consumer>
      {(context) => <Component {...props} ui={context} />}
    </UIContext.Consumer>
  );
