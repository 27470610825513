import React, { useRef, useContext } from 'react';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TweenLite } from 'gsap';

import { UIContext } from 'context/ui';

import s from './PageTransition.scss';

interface IProps {
  children: React.ReactNode;
  location: {
    pathname?: any;
    state?: any;
  };
}

export const PageTransition = ({ children, location }: IProps) => {
  const parentNode = useRef<HTMLDivElement>(null);
  const wipeNode = useRef<HTMLDivElement>(null);
  const { canTransition } = useContext<any>(UIContext);
  const duration = 450;
  const gsapTime = (duration / 1000);

  const onEnter = () => {
    TweenLite.set(wipeNode.current, { autoAlpha: 1, x: '-105%' });

    if (!parentNode.current) { return; }
    if (canTransition) {
      TweenLite.set(parentNode.current, { position: 'absolute' });
      TweenLite.to(wipeNode.current!, gsapTime, { x: '0%' });
    }
  };

  const onEntered = () => {
    TweenLite.to(wipeNode.current, gsapTime, { delay: 0.5, autoAlpha: 0 });
    if (canTransition) {
      TweenLite.to(parentNode.current, 0.5, { autoAlpha: 1 } );
    }
  };

  const onExiting = () => {
    if (canTransition) {
      TweenLite.fromTo(parentNode.current, 0.5, { autoAlpha: 0 }, { autoAlpha: 1 } );
    }
  };

  const onExited = () => {
    if (!parentNode.current) { return; }
    TweenLite.set(parentNode.current, { position: 'relative' });
  };

  return (
    <>
      <div className={s.pageTransition__wipeContainer}>
        <div className={s.pageTransition__wipe} ref={wipeNode} />
      </div>
      <TransitionGroup>
        <Transition
          key={location.pathname}
          timeout={canTransition ? duration : 0}
          onEnter={onEnter}
          onEntered={onEntered}
          onExiting={onExiting}
          onExited={onExited}
          unmountOnExit={true}
        >
        <div ref={parentNode} className={s.pageTransition__content}>
          {children}
        </div>
        </Transition>
    </TransitionGroup>
  </>
  );

};
